<template>
  <div></div>
</template>

<script>
import pdfMake from "pdfmake";
import htmlToPdfmake from "html-to-pdfmake";
import pdfFonts from "../../../assets/build/vfs_fonts";
import apiConfig from "../../../config/api";

import moment from "moment";

import { Decode, Encode } from "@/services";
import userApi from "@/api/user/";
import { toFixed } from "ant-design-vue/lib/input-number/src/utils/MiniDecimal";

import { ArabicNumberToText } from "../../../utils/numberToText";

import JsBarcode from "jsbarcode/bin/JsBarcode";

export default {
  props: {
    dataExport: Object,
  },
  data: () => ({
    newProduct: [],
    user: "",
    numberToTh: "",
  }),
  async created() {
    await this.getUser();
    await this.exports();
  },

  methods: {
    async getUser() {
      const userId = Decode.decode(localStorage.getItem("boss"));

      let responseData = [];

      try {
        responseData = await userApi.user.getAll();
      } catch (error) {
        console.log(error);
      }
      if (responseData.response_status === "SUCCESS") {
        const users = responseData.data;
        const findUser = users.find((data) => data.id === parseInt(userId));
        if (findUser) {
          this.user = findUser;
        }
      }
    },

    textToBase64Barcode(text) {
      let canvas = document.createElement("canvas");
      JsBarcode(canvas, text, { format: "CODE39", displayValue: false });

      return canvas.toDataURL("image/png");
    },

    exports() {
      const newWindow = window.open("", "_blank");

      pdfMake.vfs = pdfFonts.pdfMake.vfs;
      pdfMake.fonts = {
        Roboto: {
          normal: "Kanit-Regular.ttf",
          bold: "Kanit-Medium.ttf",
          italics: "Kanit-Medium.ttf",
          bolditalics: "Kanit-Medium.ttf",
        },
      };

      //setProduct Arr
      this.setProduct();

      // convert num to text TH
      this.convertNumberToTh();

      const html = [
        this.dataExport.is_cancel === "1"
          ? {
              table: {
                widths: ["70%", "30%"],
                body: [
                  [
                    {
                      text: `ใบเสร็จรับเงิน`,
                      fontSize: 14,
                      border: [false, false, false, false],
                      margin: [-5, -3, 0, 0],
                    },
                    {
                      rowSpan: 2,
                      text: `- ยกเลิกแล้ว -`,
                      fontSize: 16,
                      border: [false, false, false, false],
                      alignment: "right",
                      margin: [0, 0, -2, 0],
                      bold: true,
                      color: "#B22222",
                    },
                  ],
                  [
                    {
                      text: `Receipt`,
                      fontSize: 14,
                      border: [false, false, false, false],
                      margin: [-5, -5, 0, 0],
                    },
                  ],
                ],
              },
              margin: [0, 0, 0, 7],
            }
          : [
              { text: "ใบเสร็จรับเงิน", fontSize: 14 },
              { text: "Receipt", margin: [0, 0, 0, 10], fontSize: 14 },
            ],

        ,
        {
          columns: [
            {
              width: "50%",
              text: `ลูกค้า/Customer. ${this.dataExport.customer.customer_name}`,
              fontSize: 10,
            },
            {
              width: "50%",
              text: `วันที่/Issue date. ${moment(
                this.dataExport.createdAt
              ).format("DD/MM/YYYY - HH:mm:ss น.")}`,
              alignment: "right",
              fontSize: 10,
            },
          ],
          columnGap: 10,
          margin: [0, 0, 0, 5],
        },

        {
          columns: [
            {
              width: "70%",
              text: `ที่อยู่/Address. ${this.dataExport.customer_address}`,
              fontSize: 10,
            },
            {
              width: "30%",
              text: `เลขที่/No. ${this.dataExport.sales_no}`,
              alignment: "right",
              fontSize: 10,
            },
          ],
          columnGap: 10,
          margin: [0, 0, 0, 2],
        },

        {
          table: {
            widths: ["70%", "30%"],
            body: [
              [
                {
                  text: `เบอร์โทร/Tel. ${this.dataExport.customer_tel}    Email. ${this.dataExport.customer_email}`,
                  fontSize: 10,
                  border: [false, false, false, false],
                  margin: [-5, 0, 0, 0],
                },
                {
                  rowSpan: 2,
                  image: this.textToBase64Barcode(
                    `${this.dataExport.sales_no}`
                  ),
                  // qr: `${apiConfig.ENDPOINT_CLIENT}#/purchase/PU2-1/edit?id=${this.dataExport.id}`,
                  width: 130,
                  height: 35,
                  alignment: "right",
                  border: [false, false, false, false],
                  margin: [0, 0, -5, 0],
                },
              ],
              [
                {
                  text: `สาขา ${this.dataExport.customer.customer_branch}    เลขประจำตัวผู้เสียภาษี/TAX ID. ${this.dataExport.customer.customer_id_card}`,
                  fontSize: 10,
                  border: [false, false, false, false],
                  margin: [-5, 0, 0, 0],
                },
                "star-sized",
              ],
            ],
          },
        },

        // LINE
        {
          canvas: [
            {
              type: "line",
              x1: 0,
              y1: 5,
              x2: 595 - 2 * 40,
              y2: 5,
              lineWidth: 1,
            },
          ],
          margin: [0, 0, 0, 5],
        },

        {
          columns: [
            {
              width: "50%",
              text: `ผู้ออก/Issuer. ${this.user.firstName} ${this.user.lastName}`,
              fontSize: 10,
            },
            {
              width: "50%",
              text: "เลขประจำตัวผู้เสียภาษี/TAX ID.",
              fontSize: 10,
            },
          ],
          columnGap: 10,
          margin: [0, 0, 0, 5],
        },
        {
          columns: [
            {
              width: "100%",
              text: "ที่อยู่/Address.",
              fontSize: 10,
            },
          ],
          columnGap: 10,
          margin: [0, 0, 0, 5],
        },

        {
          columns: [
            {
              width: "100%",
              text: `เบอร์โทร/Tel. ${this.user.telNumber}    Email. ${this.user.email}`,
              fontSize: 10,
            },
          ],
          margin: [0, 0, 0, 5],
          columnGap: 10,
        },

        // TABLE
        {
          table: {
            widths: [50, 135, 40, 60, 60, "*", 70],
            body: this.newProduct,
          },
        },

        // REMARK

        this.dataExport.is_cancel === "1"
          ? {
              table: {
                widths: ["39.8%", "36.55%", "*"],
                body: [
                  // [],
                  [
                    {
                      text: "หมายเหตุ",
                      border: [false, false, false, false],
                      margin: [-5, 7, 0, 0],
                      fontSize: 10,
                    },
                    {
                      text: "มูลค่ารวมก่อนภาษี(บาท)/Pre Vat Amount",
                      fontSize: 9,
                      alignment: "right",
                      border: [true, false, true, true],
                    },
                    {
                      text: `${this.dataExport.total_price_before_vat}`,
                      fontSize: 9,
                      alignment: "right",
                      border: [true, false, true, true],
                    },
                  ],
                  [
                    { text: "", border: [false, false, false, false] },

                    {
                      text: "ส่วนลด(บาท)/Discount",
                      fontSize: 9,
                      alignment: "right",
                    },
                    {
                      text: `${this.dataExport.discount}`,
                      fontSize: 9,
                      alignment: "right",
                    },
                  ],
                  [
                    {
                      text: "- ยกเลิกแล้ว -",
                      border: [false, false, false, false],
                      fontSize: 9,
                      alignment: "center",
                      bold: true,
                      color: "#B22222",
                    },

                    {
                      text: "ภาษี(บาท)/VAT",
                      fontSize: 9,
                      alignment: "right",
                    },
                    {
                      text: `${this.dataExport.total_price_include_vat}`,
                      fontSize: 9,
                      alignment: "right",
                    },
                  ],
                  [
                    { text: "", border: [false, false, false, false] },

                    {
                      text: "มูลค่ารวมทั้งสิ้น(บาท)/Grand Total",
                      fontSize: 9,
                      alignment: "right",
                    },
                    {
                      text: `${this.dataExport.final_price}`,
                      fontSize: 9,
                      alignment: "right",
                    },
                  ],
                ],
              },
            }
          : {
              columns: [
                {
                  width: "38.2%",
                  text: "หมายเหตุ",
                  fontSize: 10,
                  margin: [0, 10, 0, 0],
                },
                {
                  table: {
                    widths: ["60.7%", "*"],
                    body: [
                      [
                        {
                          text: "มูลค่ารวมก่อนภาษี(บาท)/Pre Vat Amount",
                          fontSize: 9,
                          alignment: "right",
                          border: [true, false, true, true],
                        },
                        {
                          text: `${this.dataExport.total_price_before_vat}`,
                          fontSize: 9,
                          alignment: "right",
                          border: [true, false, true, true],
                        },
                      ],
                      [
                        {
                          text: "ส่วนลด(บาท)/Discount",
                          fontSize: 9,
                          alignment: "right",
                        },
                        {
                          text: `${this.dataExport.discount}`,
                          fontSize: 9,
                          alignment: "right",
                        },
                      ],
                      [
                        {
                          text: "ภาษี(บาท)/VAT",
                          fontSize: 9,
                          alignment: "right",
                        },
                        {
                          text: `${this.dataExport.total_price_include_vat}`,
                          fontSize: 9,
                          alignment: "right",
                        },
                      ],
                      [
                        {
                          text: "มูลค่ารวมทั้งสิ้น(บาท)/Grand Total",
                          fontSize: 9,
                          alignment: "right",
                        },
                        {
                          text: `${this.dataExport.final_price}`,
                          fontSize: 9,
                          alignment: "right",
                        },
                      ],
                    ],
                  },
                },
              ],
              columnGap: 10,
              margin: [0, 0, 0, 5],
            },
        ,
        // THAI TEXT
        {
          columns: [
            {
              table: {
                widths: ["50%", "50%"],
                body: [
                  [
                    {
                      text: "จำนวนเงินรวมทั้งสิ้น",
                      fontSize: 14,
                      alignment: "right",
                      border: [false, false, false, false],
                      bold: true,
                    },
                    {
                      text: this.numberToTh,
                      fontSize: 14,
                      alignment: "right",
                      border: [false, false, false, false],
                      margin: [0, 0, -5, 0],
                      bold: true,
                    },
                  ],
                ],
              },
            },
          ],
          columnGap: 10,
          margin: [0, 10, 0, 5],
        },

        // APPROVE
        {
          columns: [
            {
              width: "50%",
              text: "อนุมัติโดย/Approve by",
              fontSize: 10,
              margin: [0, 10, 0, 0],
            },
            {
              width: "50%",
              text: "รับชำระโดย/Received by",
              fontSize: 10,
              margin: [0, 10, 0, 0],
              alignment: "right",
            },
          ],
          columnGap: 10,
          margin: [0, 0, 0, 10],
        },

        // SIGNATURE
        {
          columns: [
            {
              width: "50%",
              text: "..............................................................................................",
              fontSize: 10,
              margin: [0, 10, 0, 0],
            },
            {
              width: "50%",
              text: "..............................................................................................",
              fontSize: 10,
              margin: [0, 10, 0, 0],
              alignment: "right",
            },
          ],
          columnGap: 10,
          margin: [0, 0, 0, 5],
        },
        {
          columns: [
            {
              width: "50%",
              text: "(..............................................................................................)",
              fontSize: 10,
              margin: [0, 10, 0, 0],
            },
            {
              width: "50%",
              text: "(..............................................................................................)",
              fontSize: 10,
              margin: [0, 10, 0, 0],
              alignment: "right",
            },
          ],
          columnGap: 10,
          margin: [0, 0, 0, 5],
        },
        {
          columns: [
            {
              width: "50%",
              text: "วันที่/Date.......................................................................",
              fontSize: 10,
              margin: [0, 10, 0, 0],
            },
            {
              width: "50%",
              text: "วันที่/Date........................................................................",
              fontSize: 10,
              margin: [0, 10, 0, 0],
              alignment: "right",
            },
          ],
          columnGap: 10,
          margin: [0, 0, 0, 5],
        },
      ];

      const pdfData = {
        content: html,
      };
      pdfMake.createPdf(pdfData).open({}, newWindow);
      this.$emit("generatedFile");
    },

    setProduct() {
      this.dataExport.salesOrderItems.forEach((element, index) => {
        if (index === 0) {
          this.newProduct.push([
            {
              rowSpan: 2,
              fontSize: 9,
              text: "รหัส\nID",
            },

            {
              rowSpan: 2,
              fontSize: 9,
              text: "คำอธิบาย\nDescription",
            },
            {
              rowSpan: 2,
              fontSize: 9,
              text: "จำนวน\nQuantity",
              alignment: "right",
            },
            {
              rowSpan: 2,
              fontSize: 9,
              text: "ราคา/หน่วย\nPrice/Unit",
              alignment: "right",
            },
            {
              rowSpan: 2,
              fontSize: 9,
              text: "ส่วนลด/หน่วย\nDiscount/Unit",
              alignment: "right",
            },
            {
              rowSpan: 2,
              fontSize: 9,
              text: "ภาษี (%)\nVAT (%)",
              alignment: "right",
            },
            {
              rowSpan: 2,
              fontSize: 9,
              text: "มูลค่าก่อนภาษี\nPre-Tax Amount",
              alignment: "right",
            },
          ]);
          this.newProduct.push(["rowspan"]);
        }
        this.newProduct.push([
          {
            text: element.product.product_code,
            fontSize: 9,
            alignment: "right",
          },
          {
            text: element.product.product_desc,
            fontSize: 9,
          },
          {
            text: element.item_amt,
            fontSize: 9,
            alignment: "right",
          },
          element.sales_type === "1"
            ? {
                text: !element.product.product_promotion_price
                  ? element.product.product_retail_price
                  : element.product.product_promotion_price,
                fontSize: 9,
                alignment: "right",
              }
            : {
                text: element.product.product_wholesale_price
                  ? element.product.product_wholesale_price
                  : "",
                fontSize: 9,
                alignment: "right",
              },
          {
            text: element.item_discount
              ? element.item_discount
              : (0).toFixed(2),
            fontSize: 9,
            alignment: "right",
          },
          {
            text: "7%",
            fontSize: 9,
            alignment: "right",
          },
          {
            text: element.item_total_before_vat,
            fontSize: 9,
            alignment: "right",
          },
        ]);
      });
    },

    convertNumberToTh() {
      this.numberToTh = ArabicNumberToText(this.dataExport.final_price);
    },
  },
};
</script>